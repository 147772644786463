
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useEffect } from 'react';
import { getProviders, getSession, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Grid } from '@mui/material';
import SignIn from '../src/components/auth/sign-in';
import FeaturesList from '../src/components/features-list';
export default function LoginPage({ authProviders }) {
    const { data: session } = useSession();
    const router = useRouter();
    useEffect(() => {
        if (session) {
            router.push('/dashboard');
        }
    }, [session]);
    return (<Grid container justifyContent="center" maxWidth="lg" sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            flexWrap: { md: 'nowrap' },
            paddingTop: '60px',
            gap: '90px'
        }}>
      <Grid item xs={12} sm={12} md={6}>
        <SignIn {...{ authProviders }}/>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <FeaturesList />
      </Grid>
    </Grid>);
}
// Provide list of auth providers
async function getServerSideProps(context) {
    const session = await getSession(context);
    if (session) {
        return {
            redirect: {
                destination: context.query.plan ? `/plans?plan=${context.query.plan}` : '/dashboard',
                permanent: false
            }
        };
    }
    const authProviders = await getProviders();
    return {
        props: { authProviders }
    };
}

    async function __Next_Translate__getServerSideProps__191ec9c79fe__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__191ec9c79fe__ as getServerSideProps }
  